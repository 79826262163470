import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Gigaton28k from '../images/28k-gigatons-inset.png';
import Pedersen from '../images/pedersen-glacier.png';
import GigatonOpenGraphImage from '../images/social-gigaton.png';
export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "dc-gigaton-lo.png" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Gigatons: the Measure of Climate Change" description="To understand the gigaton is to understand the impact humans are having on the planet." image={'https://storybook.earth' + GigatonOpenGraphImage} pageCanonical={'https://storybook.earth/gigaton'} mdxType="SEO" />
    <Img className="story-header-gatsby-img" fluid={props.data.imageOne.childImageSharp.fluid} mdxType="Img" />
    <div className="story-content">
      <p className="txt-low-emph txt-center">The size of roughly a gigaton of ice sitting atop the National Mall in Washington, DC. An estimated 28,000 gigatons have melted on Earth since 1994.</p>
      <div className="txt-center mvxxl">
    <h1 className="phm">Gigatons: the Measure of Climate Change</h1>
    <div className="mtm">
        <p className="mbn title-font">By Mike McDearmon</p>
        <p className="mtn txt-low-emph txt-center">Apr 22, 2021 | 4 min read</p>
    </div>
      </div>
      <p>{`Climate scientists often use the gigaton (one billion tons) to measure greenhouse gas emissions, glacier melt, and other chemical changes related to global climate. The gigaton is a unit of measure with few practical applications. At the human scale, it’s so massive that it almost has no meaning. Yet at the planetary scale a gigaton is trivial; the atmosphere alone weighs about 5 and a half million gigatons which is roughly one millionth of Earth’s total mass `}<span className="sup">{`[1]`}</span>{`.`}</p>
      <p>{`Our climate system may in fact be the sweet spot for the gigaton. It’s the scale at which rapid changes begin to nudge the system and trigger cascading effects or feedbacks. As climate scientists are observing, adding several gigatons of carbon dioxide to the atmosphere year after year is trapping heat which causes ripple effects as the planet warms. For example, that excess heat is causing ice sheets to recede and release gigatons of fresh water into the ocean. This in turn changes the chemistry and temperature of the ocean which leads to further changes, and so on.`}</p>
      <p>{`To understand the gigaton is to understand the impact humans are having on the planet, but examples of gigaton-sized objects on which to base our understanding are not easy to come by. Our cities, it turns out, can offer some perspective. Meredith Nettles, Professor of Earth and Environmental Sciences at Columbia University, offers this description of a gigaton of ice: “If you took the whole National Mall, and covered it up with ice, to a height about four times as high as the `}{`[Washington]`}{` monument… all the way down from the Capitol steps to the Lincoln Memorial” `}<span className="sup">{`[2]`}</span>{` the result would weigh about a gigaton.`}</p>
      <div className="mtxxl txt-center">
        <div className="story-image--gigaton"></div>
        <p className="phxl mbxxl caption">1 gigaton of ice would fill the National Mall up to the height of 4 Washington Monuments standing on end.</p>
      </div>
      <p>{`Now that we can picture a gigaton of ice, let’s look at how much is melting. In less than three decades from 1994 to 2020, Earth has lost a total of 28 thousand gigatons of ice `}<span className="sup">{`[3]`}</span>{`. If you were to clone our National Mall sized ice block 28,000 times, you could cover an area roughly equivalent to Vermont and Massachusetts in over 2,000 feet of ice.`}</p>
      <div className="mtxxl txt-center">
        <img src={Gigaton28k} alt="" width="100%" height="100%" />
        <p className="phxl mbxxl caption">What it would look like to gaze up at the corner of an ice sheet equivalent to all the ice that has melted on Earth between 1994 and 2020. At a height of just over 2,200 feet, this ice sheet would have a 19,600 square mile footprint.</p>
      </div>
      <p>{`While the vast majority of melting is occuring at the polar ice sheets, the impact to mountain glaciers is perhaps most visible and destabilizing to nearby communities. In Alaska alone, an average of 46 gigatons of ice melted from glaciers each year from 2003 to 2010 `}<span className="sup">{`[4]`}</span>{`. In Europe, the Alps and Caucasus Mountains could lose up to 90% of their ice by the year 2100 under some of the worst greenhouse gas emissions scenarios `}<span className="sup">{`[5]`}</span>{`. In other places like the lower 48 states, mountain glaciers are already past the point of no return. The glaciers of Glacier National Park will be gone by the end of this century no matter what we do `}<span className="sup">{`[6]`}</span>{`.`}</p>
      <div className="mtxxl txt-center">
        <img src={Pedersen} alt="" width="100%" height="100%" />
        <p className="phxl mbxxl caption">Pedersen Glacier, at Aialik Bay in Alaska’s Kenai Mountains, in 1917 (left) and 2005 (right) <span className="sup">[7]</span>.</p>
      </div>
      <p>{`All this ice is melting because we’ve heated up the planet, which we did by taking millions of years of carbon accumulated underground and putting it into the atmosphere in less than 300 years `}<span className="sup">{`[8]`}</span>{`. In total we’ve emitted over 1,500 gigatons of carbon dioxide since 1751, more than half of which went into the sky in just the last three decades `}<span className="sup">{`[9]`}</span>{`. 2019 saw a record high for carbon emissions, totaling just over 43 gigatons `}<span className="sup">{`[10]`}</span>{`. And in 2020 the Coronavirus pandemic, which ended millions of lives and ground economies around the world to near standstill, only slowed the rate of emissions by 7%. That dip is expected to rebound as communities recover from the virus`}<span className="sup">{` `}{`[11]`}</span>{`.`}</p>
      <p>{`While decades of multi-gigaton carbon emissions have already set years of warming in motion, each fraction of a degree of global temperature increase is consequential. Each one makes an adaptable future more elusive and, as climate scientist Dr. Katherine Hayhoe puts it, loads the dice against us. Mounting emissions have also led to mounting urgency, making this decade the last shot we have to preserve a future that looks at least somewhat familiar.`}</p>
      <div className="references mvxxl">
    <strong>References</strong>
    <ol>
        <li>The Editors of Encyclopaedia Britannica, <a href="https://www.britannica.com/story/how-much-does-earths-atmosphere-weigh" target="blank">How Much Does Earth’s Atmosphere Weigh?</a>, Encyclopaedia Britannica, 2021</li>
        <li>Chris Mooney, <a href="https://www.washingtonpost.com/news/energy-environment/wp/2015/07/01/meet-the-gigaton-the-huge-unit-that-scientists-use-to-track-planetary-change/" target="blank">To truly grasp what we’re doing to the planet, you need to understand this gigantic measurement</a>, The Washington Post, 2015</li>
        <li>Thomas Slater, Isobel R. Lawrence, Inès N. Otosaka, Andrew Shepherd, Noel Gourmelen, Livia Jakob, Paul Tepes, Lin Gilbert, and Peter Nienow, <a href="https://tc.copernicus.org/articles/15/233/2021/tc-15-233-2021.pdf" target="blank"> Earth’s Ice Imbalance</a>, The Cryosphere, 2021</li>
        <li>NASA Climate 365 project - a collaboration of the NASA Earth Science News Team, NASA Goddard and Jet Propulsion Laboratory communications teams, and NASA websites Earth Observatory and Global Climate Change, <a href="https://climate.nasa.gov/climate_resources/4/graphic-dramatic-glacier-melt/" target="blank">Graphic: Dramatic Glacier Melt</a>, Earth Science Communications Team at NASA's Jet Propulsion Laboratory, 2021</li>
        <li>Harry Zekollari, Matthias Huss, and Daniel Farinotti, <a href="https://www.dw.com/en/almost-all-glaciers-in-the-alps-could-disappear-by-2100-study/a-48266333" target="blank">Modelling the future evolution of glaciers in the European Alps under the EURO-CORDEX RCM ensemble</a>, The Cryosphere, 2019</li>
        <li>Caitlyn Florentine, Erich Peitzsch, Lisa McKeon, <a href="https://www.usgs.gov/centers/norock/science/retreat-glaciers-glacier-national-park?qt-science_center_objects=0#qt-science_center_objects" target="blank">Status of Glaciers in Glacier National Park</a>, United States Geologic Survey, 2021</li>
        <li>Rebecca Lindsey, <a href="https://www.climate.gov/news-features/understanding-climate/climate-change-glacier-mass-balance#:~:text=The%20pace%20of%20glacier%20loss,per%20year%20for%202010%2D2018." target="blank">Climate Change: Glacier Mass Balance</a>, NOAA, 2020</li>
        <li>David Herring, <a href="https://www.climate.gov/news-features/climate-qa/doesnt-carbon-dioxide-atmosphere-come-natural-sources" target="blank">Doesn't carbon dioxide in the atmosphere come from natural sources?</a>, NOAA, 2020</li>
        <li>Peter Frumhoff, <a href="https://blog.ucsusa.org/peter-frumhoff/global-warming-fact-co2-emissions-since-1988-764" target="blank">Global Warming Fact: More than Half of All Industrial CO2 Pollution Has Been Emitted Since 1988</a>, Union of Concerned Scientists, 2014</li>
        <li>Chelsea Harvey, Nathanial Gronewold, <a href="https://www.scientificamerican.com/article/co2-emissions-will-break-another-record-in-2019/" target="blank">CO2 Emissions Will Break Another Record in 2019</a>, Scientific American, 2019</li>
        <li>Emma Newburger, <a href="https://www.cnbc.com/2020/12/11/covid-record-drop-global-carbon-emissions-2020.html" target="blank">Covid pandemic drove a record drop in global carbon emissions in 2020</a>, CNBC, 2020</li>
    </ol>
      </div>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      